import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Countdown, { zeroPad } from 'react-countdown';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AOSService from "../../services/AOSServices";
import { useUserSettings } from '../UserSettingsProvider';
import queryString from "query-string"

import "./Login.css";
import logo from '../../assets/site_logo.png';

import cover3 from '../../assets/cover_20240708.png';
import cover3_942 from '../../assets/cover_20240409_01.png';
import cover_945 from '../../assets/cover_20231009_01.jpg';
import cover4 from '../../assets/cover_20230904.jpg';
//import cover4 from '../../assets/cover_20230904.jpg';
import cover_032 from '../../assets/cover_20231024_032.jpg';
import cover_319 from '../../assets/cover_20240409_01.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarCheck, faUserCircle } from '@fortawesome/free-solid-svg-icons'

interface TencentCaptcha {
    new (appid: string, callback?: (ret) => void, params?: Record<string, T>): {
      show: Function;
      destroy: Function;
    }
  }

const Login = (props) => {

    const queryParams = queryString.parse(window.location.search);
    const promoCode = queryParams.pm ?? "";
    const referCode = queryParams.ae ?? "";
    
    const validationSchema = Yup.object().shape({
        CountryCode: Yup.string().required(),
        Mobile: Yup.string().required("請輸入你的手機號碼"),
        OTP: Yup.string()//.required("請輸入你的驗證碼"),
    }).required();

    const { register, watch, formState: { errors } } = useForm({
        mode: "all",
        resolver: yupResolver(validationSchema),
        defaultValues: {
             //"CountryCode": "852",
             //"Mobile": "99999999",
             //"OTP": "123456"
        }
    });
    //const { isDirty, isValid, errors } = formState;

    const watchAllFields = watch();

    //const [isAuth, setIsAuth] = useState(false);
    //const [authStatus, setAuthStatus] = useState();
    const [isWaitingOTP, setIsWaitingOTP] = useState(false);
    const [errorMsg, setErrorMsg] = useState();
    const [OTPModel, setOTPModel] = useState({ Timer: 180, Prefix: "" });
    const [OTPInterval, setOTPInterval] = useState(Date.now() + (1000 * OTPModel.Timer));
    const { WizardInstance } = props;
    const { setUserRef } = useUserSettings();
   

    const refOTPCountdown = React.createRef();
    //const refTbxOTP = React.createRef();



    // useEffect(() => {
    //     //
    //     const subscription = watch((value, { name, type }) => 
    //     return () => subscription.unsubscribe();
    // }, [watch]);

    const getOTP = async () => {
        if (watchAllFields.CountryCode && watchAllFields.Mobile) {
            setErrorMsg("");
            setOTPModel({ Prefix: "", ...OTPModel });
            setIsWaitingOTP(true);

            //refTbxOTP.current.disabled = false;
            const countDownRef = refOTPCountdown.current;

            const { ticket, randstr } = await onCaptchaShow();

            AOSService.ReqOTP(watchAllFields.CountryCode, watchAllFields.Mobile, ticket, randstr)
                .then(res => {
                    if (res) {
                        const { Prefix, Timer, Result } = res;
                        if (Result === 1) {
                            setOTPModel({ Prefix, Timer });
                            setOTPInterval(Date.now() + (1000 * OTPModel.Timer));
                            countDownRef.start();
                        } else if (Result === -1) {
                            setErrorMsg("你所提供的手機號碼已經登記開戶。");
                            setIsWaitingOTP(false);
                        } else if (Result === -3) {
                            setErrorMsg("内地居民身份证开户：客户须身处香港境内或海外地区");
                            setIsWaitingOTP(false);
                        }
                    }
                });
        }
    };

    const readyToGetOTP = () => (!isWaitingOTP && watchAllFields.Mobile?.length > 5);
    const isValid = () => (watchAllFields.Mobile?.length > 5 && watchAllFields?.OTP.length === 6);

    const countdownLayout = ({ hours, minutes, seconds, completed }) => {
        return (
            <span>獲取驗證碼
                {(completed) ? null : isWaitingOTP ? (<>({zeroPad(minutes)}:{zeroPad(seconds)})</>) : null}
            </span>);
    };

    const countdownCompleted = () => {
        setIsWaitingOTP(false);
    };

    const validateOTP = (nextStep = null) => {
        
        const data = watchAllFields;
        const referrerCode = queryParams.ae ?? "";
        const promoCode = queryParams.pm ?? "";
        //console.log('ae:'+referrerCode);
        //console.log(userRef, "pre");
        //ApiService.post("/home/validateotp", JSON.stringify(data))
        AOSService.ValidateOTP(data.CountryCode, data.Mobile, data.OTP, referrerCode, promoCode, "")
            .then(res => {
                if (res && res.Result) {
                    //console.log(res, "res");
                    if (WizardInstance.goToStep) {
                        setUserRef(res.UserRef);
                        //console.log(userRef, "dump");

                        const step = nextStep || res.UserRef.InitialStep || "IDType";
                        WizardInstance.goToNamedStep(step);
                    } else {
                        alert("login error!!");
                    }
                } else {
                    setErrorMsg("短訊驗證碼不正確");
                }
            });
    };

    const onCaptchaShow = async () => {
        return new Promise((resolve, reject) => {

            const callback = (res) => {
                console.log(res);
                // res（用户主动关闭验证码）= {ret: 2, ticket: null}
                // res（验证成功） = {ret: 0, ticket: "String", randstr: "String"}
                // res（请求验证码发生错误，验证码自动返回terror_前缀的容灾票据） = {ret: 0, ticket: "String", randstr: "String",  errorCode: Number, errorMessage: "String"}
                if (res.ret === 0) {
                    resolve(res);
                } else {
                    alert(res.errorMessage);
                    reject(res.errorMessage);
                }
            }

            const appid = "189945523";

            try {
                const captcha = new TencentCaptcha(document.getElementById("cap_iframe"), appid, callback, {})
                captcha.show()
            } catch (error) {
                var ticket = "terror_1001_" + appid + "_" + Math.floor(new Date().getTime() / 1000)
                callback({
                    ret: 0,
                    randstr: "@" + Math.random().toString(36).substr(2),
                    ticket: ticket,
                    errorCode: 1001,
                    errorMessage: "jsload_error"
                })
            }
        })
    }

    const submitForm = () => validateOTP();
    const submitOpeningBooking = () => validateOTP("OpeningBooking");

    const AECode = (code) => [`AE${code}`, `C${code}`, code];
    const AECodeList = (codeList) => codeList.reduce((ori, currentValue) => [...ori, ...AECode(currentValue)], []);
    // const isReferList = (codeList) => codeList.includes(referCode);
    const isReferAEList = (codeList) => AECodeList(codeList).includes(referCode);
    const bookingAllowedAE = ["032", "319", "571", "634", "941", "942", "943"];
    const isAllowBooking = () => isReferAEList(bookingAllowedAE);

    const ShowWelcomeMsg = () =>{
        if(isReferAEList(["942"]))
        {
            return (
            <>
                <h4 className="slogan text-center"> 立即註冊領取 </h4>
                <h5 className="slogan text-center"> 100 張 0 佣優惠 </h5>
            </>
            );
        }
        else if(isReferAEList(["945"]))
        {
            return (
            <>
                <h4 className="slogan text-center"> 立即註冊領取 </h4>
                <h5 className="slogan text-center"> HK$500 沐浴禮券 </h5>
            </>
            );
        }
        else
        {
            return (
            <>
                <h4 className="slogan text-center">開戶送大禮</h4>
                <h5 className="slogan text-center">只需3分鐘  立即行動啦！</h5>
            </>
            );
        }
        
    };

    const ShowBanner = () =>{

        if (isReferAEList(["942"]))
        {
            return (
                <a href="https://www.ffg.com.hk/promotions/free100futures" className="m-auto">
                    <img src={cover3_942} alt="" className="p-0 img-responsive img-fluid" />
                </a>
            );
        }
        else if(isReferAEList(["943"]))
        {
            return (
                <a href="https://www.ffg.com.hk/promotions/futurepromotionhkpost" className="m-auto">
                    <img src={cover4} alt="" className="p-0 img-responsive img-fluid" />
                </a>
            );
        }
        else if(isReferAEList(["945"]))
        {
            return (
                <a href="https://www.ffg.com.hk/promotions/accountopening_mooodmassage" className="m-auto">
                    <img src={cover_945} alt="" className="p-0 img-responsive img-fluid" />
                </a>
            );
        }
        else if(isReferAEList(["032"]))
        {
            return (
                <img src={cover_032} alt="" className="p-0 img-responsive img-fluid" />
            );
        }
        else if(isReferAEList(["319"]))
        {
            return (
                <a href="https://www.ffg.com.hk/promotions/future100free" className="m-auto">
                    <img src={cover_319} alt="" className="p-0 img-responsive img-fluid" />
                </a>
            );
        }
        else
        {
            return (
                <a href="https://www.ffg.com.hk/promotions/free_quote" className="m-auto">
                    <img src={cover3} alt="" className="p-0 img-responsive img-fluid" />
                </a>
            );
        }
    };

    return (
        <div className="login-bg">
            <Container className="h-100 p-0 d-flex g-0">
                <form className="login-card my-0 p-0 my-auto mx-auto">
                    <Row>
                        <Col lg={6}>
                            <div className="p-3">
                                <div className="text-center mb-4">
                                    <img className="ffg-logo my-lg-4 my-2" src={logo} alt="" />

                                    <ShowWelcomeMsg/>
                                </div>

                                {/* <p className="page-title text-center my-lg-3">登入/註冊</p> */}

                                {(promoCode && (promoCode !== "NA")) && (
                                <div className="px-4 d-grid gap-2 col-12 mx-auto my-2">
                                    <span className="input-group-text d-block text-center text-danger border-0 col-12 text-uppercase fw-bold pm-code-bg"
                                            style={{ backgroundColor: "#fff" }}> 
                                        {promoCode}
                                        
                                        {(referCode) && (
                                            <span className="px-2">[{referCode}]</span>
                                        )}
                                    </span>
                                </div>
                                )}

                                <div className="px-4 my-2">
                                    <select
                                        {...register("CountryCode")}
                                        className={`form-select form-select-lg mb-3`}
                                        disabled={isWaitingOTP}
                                    >
                                        <option value="852"> 中國香港 </option>
                                        <option value="86"> 中國內地 </option>
                                        <option value="853"> 中國澳門 </option>
                                        <option value="886"> 中國台灣 </option>
                                    </select>
                                </div>

                                <div className="px-4 my-2">
                                    <div className={`input-group input-group-lg mb-3`}>
                                        <span className="input-group-text d-block text-center border-0 col-3"
                                            style={{ backgroundColor: "#fff" }}> + {watchAllFields.CountryCode} </span>
                                        <input
                                            {...register("Mobile")}
                                            disabled={isWaitingOTP}
                                            className={`form-control form-control-lg px-2 ${errors.Mobile ? 'is-invalid' : "border-0"}`}
                                            aria-label="手機號碼"
                                            placeholder="請輸入你的手機號碼" />
                                        {errors.Mobile &&
                                            <div className="invalid-feedback">
                                                {errors.Mobile.message}
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="px-4 my-2 d-grid gap-2 d-md-flex justify-content-md-end">
                                    <button type="button"
                                        className="btn btn-block btn-warning"
                                        disabled={!readyToGetOTP()}
                                        onClick={() => getOTP()}
                                    >
                                        <Countdown
                                            ref={refOTPCountdown}
                                            date={OTPInterval}
                                            renderer={countdownLayout}
                                            precision={2}
                                            autoStart={false} onComplete={countdownCompleted} />
                                    </button>
                                </div>

                                <div className="px-4 my-2">
                                    <div id="cap_iframe"></div>
                                </div>

                                <div className="px-4 my-2">
                                    <div className={`input-group input-group-lg mb-3`}>
                                        <span className="input-group-text d-block text-center text-danger border-0 col-3"
                                            style={{ backgroundColor: "#fff" }}> {OTPModel.Prefix} </span>
                                        <input type="text"
                                            {...register("OTP")}
                                            maxLength={6}
                                            //ref={refTbxOTP}
                                            //disabled={true}
                                            className={`form-control form-control-lg px-2 ${errors.OTP ? 'is-invalid' : "border-0"}`}
                                            placeholder="請輸入短訊驗證碼" />
                                        {errors.OTP &&
                                            <div className="invalid-feedback">
                                                {errors.OTP.message}
                                            </div>
                                        }
                                    </div>
                                </div>

                                
                                <div className="row px-2">
                                {(!referCode && (referCode || []).length === 0) || isAllowBooking() ? (
                                    <>
                                        <div className="px-4 d-grid gap-2 col-sm-12 col-md-6 mx-auto my-2">
                                            <button type="button"
                                                disabled={!isValid()}
                                                className="btn btn-block btn-danger"
                                                onClick={submitForm}
                                            >
                                                <FontAwesomeIcon icon={faUserCircle} bounce style={{paddingRight: 12, color:"#fec440"}}  />
                                                <span>註冊 / 開戶</span> 
                                            </button>
                                        </div>

                                        <div className="px-4 d-grid gap-2 col-sm-12 col-md-6 mx-auto my-2">
                                            <button type="button"
                                                disabled={!isValid()}
                                                className="btn btn-block btn-danger"
                                                onClick={submitOpeningBooking}
                                            >
                                                <FontAwesomeIcon icon={faCalendarCheck} bounce style={{paddingRight: 12}} />
                                                 <span>預約開戶</span>
                                            </button>
                                        </div>
                                    </>
                                ) : (
                                    <div className="px-4 d-grid gap-2 col-12 mx-auto my-2">
                                        <button type="button"
                                            disabled={!isValid()}
                                            className="btn btn-block btn-danger"
                                            onClick={submitForm}
                                        >
                                            登入
                                        </button>
                                    </div>
                                )}
                                </div>

                                {errorMsg && (<div className="px-4 d-grid gap-2 col-12 mx-auto my-2 alert alert-warning" role="alert">{errorMsg}</div>)}
                            </div>
                        </Col>
                        <Col lg={{ span: 6, offset: 0 }} md="12" xs="12" className="order-lg-last order-md-first order-xs-first align-middle d-inline-flex">
                            
                        <ShowBanner />
                            
                        </Col>
                    </Row>
                </form>
            </Container>
        </div>
    );
};

export default Login;
