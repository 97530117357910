import ApiService from "../http-common";

let fn = {};

fn.ReqOTP = (CountryCode, Mobile, Ticket, Randstr) => {
  return ApiService.post("/home/requestotp", JSON.stringify({ CountryCode, Mobile, Ticket, Randstr }));
};

fn.ValidateOTP = (CountryCode, Mobile, OTP, ReferrerCode, PromoCode, InviteCode) => ApiService.post("/home/validateotp", JSON.stringify({ CountryCode, Mobile, OTP, ReferrerCode, PromoCode, InviteCode }));

fn.OpeningBooking = (ClientName) => ApiService.post("/home/OpeningBooking", JSON.stringify({ ClientName }));

fn.GetIDCard = (region, refCode, side) => {
  return ApiService.get("/home/GetIDCard", {
    params: {
      Region: region,
      RefCode: refCode,
      Side: side
    },
    responseType: 'arraybuffer'
  });
}

fn.UploadID = (region, refCode, side, file, onUploadProgress) => {
  let formData = new FormData();

  formData.append("Files", file, file.fileName);
  formData.append("Region", region);
  formData.append("RefCode", refCode);
  formData.append("Side", side);

  return ApiService.post("/home/UploadIDCard", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
};

fn.UploadVideo = (refCode, step, file, onUploadProgress = null) => {
  let formData = new FormData();

  formData.append("Files", file, file.fileName?? file.name);
  formData.append("RefCode", refCode);
  formData.append("NextSN", step);

  return ApiService.post("/home/UploadSelfie", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
};

fn.UploadSignature = (refCode, step, file) => {
  let formData = new FormData();

  formData.append("Files", file, file.fileName);
  formData.append("RefCode", refCode);
  formData.append("NextSN", step);

  return ApiService.post("/home/SaveSignature", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  });
};

fn.GetBankList = () => ApiService.get("/home/BankList");
fn.GetCountryList = () => ApiService.get("/home/CountryList");
fn.GetCompanyNatureList = () => ApiService.get("/home/CompanyNatureList");
fn.GetStateList = () => ApiService.get("/home/StateList");
fn.GetCityList = () => ApiService.get("/home/CityList");

fn.GetInfo = (refCode, region) => ApiService.get("/home/GetInfo", { params: { refCode, region } });
fn.SavePersonalInfo = (refCode, obj, step) => ApiService.post(`/home/SavePersonalInfo`, JSON.stringify({
  refCode,
  Data: obj,
  NextSN: step
}));

fn.GetSettlementInfo = (refCode) => ApiService.get("/home/GetSettlementInfo", { params: { refCode } });
fn.SaveSettlementInfo = (refCode, obj, step) => ApiService.post(`/home/SaveSettlementInfo`, JSON.stringify({
  refCode,
  Data: obj,
  NextSN: step
}));

fn.GetFFGAccountInfo = (refCode) => ApiService.get("/home/GetFFGAccountInfo", { params: { refCode } });
fn.SaveFFGAccountInfo = (refCode, obj, step) => ApiService.post(`/home/SaveFFGAccountInfo`, JSON.stringify({
  refCode,
  Data: obj,
  NextSN: step
}));


fn.GetSelfDisclosureInfo = (refCode) => ApiService.get("/home/GetSelfDisclosureInfo", { params: { refCode } });
fn.SaveSelfDisclosureInfo = (refCode, obj, step) => ApiService.post(`/home/SaveSelfDisclosureInfo`, JSON.stringify({
  refCode,
  Data: obj,
  NextSN: step
}));

fn.GetRiskDisclosure = (refCode) => ApiService.get("/home/GetRiskDisclosure", { params: { refCode } });
fn.SaveRiskDisclosure = (refCode, obj, step) => ApiService.post(`/home/SaveRiskDisclosure`, JSON.stringify({
  refCode,
  Data: obj,
  NextSN: step
}));

fn.GetEmploymentInfo = (refCode) => ApiService.get("/home/GetEmploymentInfo", { params: { refCode } });
fn.SaveEmploymentInfo = (refCode, obj, step) => ApiService.post(`/home/SaveEmploymentInfo`, JSON.stringify({
  refCode,
  Data: obj,
  NextSN: step
}));

fn.GetFinancialInfo = (refCode) => ApiService.get("/home/GetFinancialInfo", { params: { refCode } });
fn.SaveFinancialInfo = (refCode, obj, step) => ApiService.post(`/home/SaveFinancialInfo`, JSON.stringify({
  refCode,
  Data: obj,
  NextSN: step
}));

fn.GetDocList = (refCode, AType) => ApiService.get("/home/GetDocList", { params: { refCode, AType } });
fn.DeleteDoc = (refCode, ID) => {
  let formData = new FormData();
  formData.append("refCode", refCode);
  formData.append("ID", ID);
  return ApiService.post(`/home/DeleteDoc`, formData);
};
fn.UploadDoc = (refCode, AType, file, onUploadProgress) => {
  let formData = new FormData();

  formData.append("Files", file, file.fileName);
  formData.append("refCode", refCode);
  formData.append("AType", AType);

  return ApiService.post("/home/UploadDoc", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
}

fn.SaveAOStatusInfo = (refCode, obj) => ApiService.post(`/home/SaveAOStatusInfo`, JSON.stringify({
  refCode,
  Data: obj
}));

// fn.SaveApplication = (action, refCode, obj) => {
//   switch (action.toUpperCase()) {
//     case "PINFO": return ApiService.post(`/home/SavePersonalInfo?refcode=${refCode}`, JSON.stringify(obj));
//     default:
//       return new Promise(res=>{
//         alert("internal error");
//       })
//   }
// };

const AOSServices = fn;
export default AOSServices;