import React, { useEffect } from "react";
import { useUserSettings } from '../UserSettingsProvider';
import StepContainer from '../StepContainer/StepContainer';
import AOSServices from "../../services/AOSServices";
import { FBLabel, InvalidFieldStyle, PrettyPrintJson, cssFormInput } from '../../services/Utils';
import { RadioButtonList } from "../FFGCheckbox/FFGCheckbox";
import DocUploader from '../DocUploader/DocUploader';

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { subMonths, format } from 'date-fns';
import zh from 'date-fns/locale/zh-CN';
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import qr from '../../assets/wechat_qr.jpg';

const AOStatusInfo = (props) => {
    const { userRef } = useUserSettings();
    const [hideTransfer, setHideTransfer] = React.useState(false);
    registerLocale('zh', zh);

    useEffect(() => {
        if (userRef.IDCardOrigin === "CHN" && !userRef.HasHKGBankAcc) {
            setHideTransfer("BioCheck", true);
        }
    }, [])


    //console.log(`userRef:` + userRef);
    //console.log(`origin:` + userRef.IDCardOrigin);
    //console.log(`hashkbank:` + userRef.HasHKGBankAcc);
    //console.log(`hideTransfer:` + hideTransfer);

    const validationSchema = Yup.object().shape({
        FTBank: Yup.string().nullable().optional(),//.required("請輸入入賬銀行"),
        FTTransferDate: Yup.date().nullable().optional(),//.required("請輸入轉賬日期"),
        FTAmount: Yup.object().when('FTBank', (val, schema) => {
                if(val === null || val === undefined || val === '')
                    return schema.nullable();
                else {
                    return Yup.number().typeError('請輸入轉賬金額').required("請輸入轉賬金額").min(10000, "轉賬金額不可少於港幣 $10,000");
                }
            }),
            //.typeError('請輸入轉賬金額')
            //.required("請輸入轉賬金額")
            //.min(10000, "轉賬金額不可少於港幣 $10,000"),
    });

    const { register, trigger, watch, getValues, setError, setValue, handleSubmit, reset, control,
        formState: { isValid, errors }, clearErrors } = useForm({
            mode: "all",
            shouldFocusError: true,
            resolver: yupResolver(validationSchema),
            defaultValues: {
                FTTransferDate: new Date()
            }
        });

    const onSubmit = (data) => {
        //console.log(JSON.stringify(data));
        AOSServices.SaveAOStatusInfo(userRef.RefCode, data)
            .then(r => {
                if (r && r.Result) {
                    alert("提交成功");
                } else {
                    alert("提交資料失敗，請重新再試");
                }
            });
        
    };

    const HasSecuritiesAcc = () => userRef.FBAcc?.some(w => w === "S");

    return (

        <StepContainer showNavigation={true}
            {...props}>

            <div className="row">
                <div className="col-12">
                    <h3 className="pt-3"> 開戶狀況 </h3>
                </div>

                <div className="col-12 text-start">


                    {(!hideTransfer) ? (
                        <>
                            <h4 className="py-3 fb-red text-center">我們已收到您的開戶申請，在您完成下列步驟後，將會通過短信及電郵通知。</h4>

                            <div className="card">
                                <div className="card-body">
                                
                                    <h5 className="mt-3 py-1 fb-red">進行客戶身份見證</h5>
                                    <h5 className="mt-3 py-1 fb-red">步驟1. 轉帳港幣$10,000或以上至本公司的銀行帳戶</h5>
                                    <p>
                                        請客戶透過剛才登記的提款銀行帳戶轉賬港幣$10,000元或以上至本公司帳號，以作為開戶時認可的身份見證。
                                    </p>

                                    <p>
                                        轉賬渠道(建議使用「轉數快FPS」方式轉賬)，詳情請點擊 <a href="https://www.ffg.com.hk/customer-service/deposit-to-fulbright-account">此連結</a>
                                    </p>

                                    (*為加快完成開戶程序，請轉賬至本公司工銀亞洲銀行賬戶)

                                    <h5 className="mt-3 py-1">存入資金方法</h5>

                                    <table className="table table-bordered">
                                        <tbody>
                                            {HasSecuritiesAcc() && (
                                                <>
                                                    <tr>
                                                        <td rowSpan={2}>
                                                            富昌證券戶口
                                                        </td>
                                                        <td>
                                                            工銀亞洲
                                                        </td>
                                                        <td>
                                                            戶口名稱:FULBRIGHT SECURITIES LIMITED-CLIENTS A/C<br />
                                                            072-861-502122612 (港元)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            轉數快 FPS
                                                        </td>
                                                        <td>
                                                            161511282
                                                        </td>
                                                    </tr>
                                                </>
                                            )}

                                            {!HasSecuritiesAcc() && (
                                                <>
                                                    <tr>
                                                        <td rowSpan={2}>
                                                            富昌期貨戶口
                                                        </td>
                                                        <td>
                                                            工銀亞洲
                                                        </td>
                                                        <td>
                                                            戶口名稱:FULBRIGHT FUTURES LIMITED-CLIENTS A/C<br />
                                                            072-861-502122638 (港元) [推薦]
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            匯豐銀行
                                                        </td>
                                                        <td>
                                                            戶口名稱:FULBRIGHT FUTURES LIMITED <br />
                                                            502-862014-001 (港元)<br /><br />
                                                            轉數快 FPS 編號: 4547741<br />
                                                        </td>
                                                    </tr>
                                                </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>) : <>
                            <h4 className="py-3 fb-red text-center">我们已收到您的开户申请，审核通过后，您将收到短信及电邮通知。</h4>
                        </>
                    }
                    <hr className="dot-line" />


                    {(!hideTransfer) ? (
                        <div className="card">
                            <div className="card-body">

                                <h5 className="mt-3 py-1 fb-red">步驟2. 上傳轉賬憑證(入數紙)</h5>
                                <p>
                                    客戶可立即上傳轉賬憑證(入數紙) 或 稍後重新登錄本開戶快網站進行有關程序。
                                </p>

                                {userRef.IDCardOrigin === "CHN" ?
                                    ("[(入境紙): 請提供閣下到訪香港的入境標籤]") : <></>
                                }

                                <h5 className="mt-3 py-1">轉賬憑證(入數紙)</h5>

                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">

                                        {/* <FBLabel className={cssFormLabelCol} text="入賬銀行:" required={1}></FBLabel>
                            <div className={cssFormInputCol}>
                                <input
                                    {...register("EmployerName")}
                                    type="text"
                                    className={`form-control ${InvalidFieldStyle(errors?.EmployerName)}`}
                                />
                                <div className="invalid-feedback"> {errors?.EmployerName?.message} </div>
                            </div> */}

                                        <div className="col-sm-4 col-xs-12 my-0 my-md-2">
                                            {/* <FBLabel text="入賬銀行:" required={1}></FBLabel> */}
                                            <FBLabel text="入賬銀行:"></FBLabel>
                                            <select
                                                {...register("FTBank")}
                                                className={`form-select mb-0 ${InvalidFieldStyle(errors?.FTBank)}`}
                                            >
                                                <option></option>
                                                {HasSecuritiesAcc() && (
                                                    <>
                                                        <option value="ICBC">[港元] 工銀亞洲 (072-861-502122-612)</option>
                                                        <option value="ICBC">[港元] 工銀亞洲 (161511282) [轉數快]</option>
                                                        <option value="HSBCT">[港元] 匯豐銀行 (4177044) [轉數快]</option>
                                                    </>
                                                )}

                                                {!HasSecuritiesAcc() && (
                                                    <>
                                                        <option value="ICBC22638">工銀亞洲(港元) (072-861-502122-638)</option>
                                                        <option value="HKB14001">匯豐銀行(港元) (502-862014-001) [轉數快]</option>
                                                    </>
                                                )}
                                            </select>
                                            <div className="invalid-feedback"> {errors?.FTBank?.message} </div>
                                        </div>

                                        <div className="col-sm-4 col-xs-12 my-0 my-md-2">
                                            {/* <FBLabel text="轉賬日期:" required={1}></FBLabel> */}
                                            <FBLabel text="轉賬日期:"></FBLabel>
                                            <Controller
                                                name="FTTransferDate"
                                                control={control}
                                                render={({ field }) => (
                                                    <ReactDatePicker
                                                        className={`form-control ${(errors?.FTTransferDate) ? 'is-invalid' : ""}`}
                                                        placeholderText=""
                                                        dateFormat="yyyy-MM-dd"
                                                        locale="zh"
                                                        showYearDropdown
                                                        scrollableYearDropdown
                                                        yearDropdownItemNumber={60}
                                                        withPortal
                                                        maxDate={new Date()}
                                                        onChange={(e) => field.onChange(e)}
                                                        selected={field.value}
                                                    />
                                                )}
                                            />
                                            <div className="invalid-feedback">
                                                {errors?.FTTransferDate?.message}
                                            </div>
                                        </div>

                                        <div className="col-sm-4 col-xs-12 my-0 my-md-2">
                                            {/* <FBLabel text="金額(港元):" required={1}></FBLabel> */}
                                            <FBLabel text="金額(港元):"></FBLabel>
                                            <input
                                                {...register("FTAmount")}
                                                type="number"
                                                className={`form-control ${InvalidFieldStyle(errors?.FTAmount)}`}
                                            />
                                            <div className="invalid-feedback"> {errors?.FTAmount?.message} </div>
                                        </div>

                                        <div className="col-sm-12 col-xs-12 my-0 my-md-2">
                                            {/* <FBLabel text="入數紙:" required={1}></FBLabel> */}
                                            <FBLabel text="入數紙:"></FBLabel>
                                            <DocUploader AType="BankSlip" RefCode={userRef.RefCode}></DocUploader>
                                        </div>

                                        <div className="col-sm-12 col-xs-12 my-0 my-md-2">
                                            <FBLabel text="其他文件:"></FBLabel>
                                            <DocUploader AType="Other" RefCode={userRef.RefCode}></DocUploader>
                                        </div>

                                        <div className="d-grid gap-2">
                                            <button className="btn btn-danger" type="submit">
                                                提交
                                            </button>
                                        </div>


                                    </div>
                                </form>
                            </div>
                        </div>
                    ) : <div></div>
                    }

                    <p className="subscript mt-3">
                        如有查詢，請致電 (852) 3108-3333或 國內免費熱線 4001-200-899。<br />
                        “富昌金融集團”公眾號（微信號：ffgofficial）提供客服諮詢服務，歡迎關注。
                    </p>

                    <img src={qr} alt="" />

                </div>

            </div>
        </StepContainer>
    )
}

export default AOStatusInfo;